.grid-3-col {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 100px 20px;
    place-items: center;
}

@media screen and (max-width:500px) {
    .grid-3-col {
        grid-template-columns: 1fr;
    }
}

img {
    width: 100%;
    margin: 0 auto;
    transition: all .5s ease-in-out;
}

img:hover {
    transform: scale(1.15);
    margin-bottom: 60px;
}

.pricing {
    width: fit-content;
    padding: 5px 10px;
    background-color: black;
    border-radius: 16px;
    color: white;
}

img, .pricing, .add-cart {
    display: block;
    margin: 10px auto;
}


.product-name {
    text-align: center;
    font-weight: 500;
    font-size: 21px;
}

.item {
    background-color: grey;
}

.add-cart {
    width: fit-content;
    background-color: white;
    border: 2px solid #3D4DB2;
    border-radius: 16px;
    padding: 5px 20px;
}

.add-cart:hover {
    color: white;
    background-color: #3D4DB2;
}

/* .checkout-wrapper{
    padding:0 30px;
    display: flex;
    flex-direction: column;
    row-gap: 50px;
} */


.black-btn{
    cursor: pointer;
    background-color: black;
    text-align: center;
    padding:15px 20px;
    border:none;
    border-radius: 10px;
    color: white;
    border: none;
    margin: 10px 20px;
}

.cart-btns{
    margin-top: 50px;
    display: flex;
    justify-content: center;
}

.checkout-listing{
    width: 40%;
    margin:0 auto;
    display:flex;
    align-items: center;
    margin-top: 100px;
    border-radius: 16px;
    column-gap: 10%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.checkout-listing>img{
    width: 300px;
    margin:0;
}


.checkout-listing>div{
}