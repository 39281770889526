.navbar{
  background-color:black;
  width:100vw;
}

.nav-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:30px;
}

.brand-logo{
  font-size: 30px;
  font-weight: 700;
}

.nav-container a{
  color:white;
  text-decoration: none;
  padding-right: 20px;
}

.main-heading{
  text-align: center;
}

.cart-icon{
  position: relative;
  top:5px;
}